import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import TickWhite from "../../../assets/img/tick_white.svg";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Asterisk } from "../../../utils/helperFunctions";
import { DatePicker, Select } from "antd";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Calendar from "react-calendar";
import Loader from "../../common/Loader";

const { Option } = Select;

const ToastMessage = ({ type }) => (
	<div
		className="reversal_toast"
		style={{
			"--toast-status": "#35CB00",
			width: "300px",
		}}
	>
		<img src={TickWhite} alt={"success"} className="mr-2" style={{ width: "1.2rem" }} />
		Holiday saved successfully.
	</div>
);

export default function AddHolidayList() {
	const { centerList } = useSelector((state) => state.auth);
	const [selectedCenter, setSelectedCenter] = useState("");
	const { state } = useLocation();
	let navigate = useNavigate();
	const [isLoading, setisLoading] = useState(false);
	const [holidayMakring, setholidayMakring] = useState([]);
	const [holiday_d, setHoliday_d] = useState([]);

	const { section_id, liveclass_id, date, liveClassStartDate, batchName, subjectName, school_id } =
		state !== null
			? state
			: {
					section_id: null,
					liveclass_id: null,
					date: null,
					liveClassStartDate: null,
					batchName: null,
					subjectName: null,
			  };
	let data = localStorage.getItem("userdetail");
	data = JSON.parse(data);
	const token = data.token;

	async function postholiday() {
		let postData = {
			holiday_data: holidayMakring,
		};
		const data = await Api.doFetch("PATCH", postData, ApiUrl.STUDENT_BATCH_MARK_Holiday);
		if (data.status) {
            setisLoading(true);
		    getholiday(selectedCenter);
			toast(<ToastMessage />);
		} else {
			Swal.fire("Error!", "Internal Server Error", "error");
		}
	}

	const getholiday = async (value) => {
		const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_BATCH_GET_Holiday}?school_id=${value}`);
		if (data.status) {
			var a = [];
			data.payload.map((item, index) => {
				if (item.is_holiday) {
					a.push(item.holiday_date);
				}
			});
			var b = [];
			data.payload.map((item, index) => {
				if (item.is_holiday) {
					b.push({
						school_id: item.school_assoc.id,
						holiday_date: item.holiday_date,
						is_holiday: item.is_holiday,
					});
				}
			});
			setholidayMakring(b);
			setHoliday_d(a);
			setisLoading(false);
		}
	};

	const handleCenterChange = (value) => {
		setSelectedCenter(value || "");
		setisLoading(true);
		getholiday(value);
	};

	useEffect(() => {
		console.log("************************");
		console.log(holiday_d);
		console.log(holidayMakring);
	}, [holidayMakring]);

	return (
		<div>
			{isLoading ? (
				<Loader />
			) : (
				<DialogContent>
					<div style={{ padding: 10 }} className="antd_dropdown">
						<div style={{ marginBottom: "20px" }}>
							<p className="add_course_drop_header">
								Select Center <Asterisk />
							</p>
							<Select
								className="full-width"
								value={selectedCenter || undefined}
								placeholder="Select center"
								onChange={handleCenterChange}
								showArrow
								allowClear
								showSearch
								optionFilterProp="children"
							>
								{centerList.map((school) => {
									return (
										<Option key={school.school_id} value={school.school_id}>
											{school.school_name}
										</Option>
									);
								})}
							</Select>
						</div>
					</div>
				</DialogContent>
			)}
			<div style={{ placeContent: "center", display: "flex" }}>
				{Boolean(selectedCenter) && (
					<Calendar
						tileClassName={({ date, view }) => {
							const d = new Date(date);
							let iterDate = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
							if (holiday_d.includes(iterDate)) {
								return `react-calendar__tile--now-holiday`;
							}
							return `react-calendar__tile--now-absent`;
						}}
						onClickDay={(d) => {
							let iterDate = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
							const existingMarkEntry = holidayMakring.find((item) => item.holiday_date == iterDate);
							setHoliday_d((prev) => (prev.includes(iterDate) ? prev.filter((item) => item !== iterDate) : [...prev, iterDate]));
							if (existingMarkEntry) {
								// If it exists, update the object
								setholidayMakring((prev) =>
									prev.map((item) => (item.holiday_date === iterDate ? { ...item, is_holiday: !item.is_holiday } : item))
								);
							} else {
								// If it doesn't exist, add a new object
								setholidayMakring((prev) => [
									...prev,
									{
										school_id: selectedCenter,
										holiday_date: iterDate,
										is_holiday: true,
									},
								]);
							}
						}}
					/>
				)}
			</div>
			<div style={{ placeContent: "center", display: "flex" }}>
				{holidayMakring.length > 0 && (
					<button
						className="btn modal-btn-submit"
						style={{
							justifyContent: "center",
							marginTop: 10,
						}}
						onClick={() => {
							postholiday();
						}}
					>
						Submit
					</button>
				)}
			</div>
		</div>
	);
}
