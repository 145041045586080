import React, { useEffect, useState } from "react";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import { useSelector } from "react-redux";
import { Asterisk } from "../../../utils/helperFunctions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogContent,
    DialogTitle
} from "@mui/material";
import Iicon from "../../student/assets/attendance Module/akar-icons_circle-alert.svg";
import { DatePicker, Select } from 'antd';

const { Option } = Select;

const formatClassTime = date => date ? moment(date).format("hh:mm A") : "";

const SelectLivClassDialoge = ({ isOpen, close, whichPageToOpen }) => {
    const { centerList } = useSelector((state) => state.auth);

    const [selectedCenter, setSelectedCenter] = useState("");
    const [selectedCourse, setSelectedCourse] = useState("");
    const [selectedBatch, setSelectedBatch] = useState("");
    const [liveClassSubject, setLiveClassSubject] = useState("");
    const [liveClassIdList, setLiveClassIdList] = useState([])
    const [liveClassStartDate, setLiveClassStartDate] = useState(null);
    const [selectedLiveClass, setSelectedLiveClass] = useState("");
    
    const [selectedDate, setSelectedDate] = useState(moment());

    const [courseList, setCourseList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [liveClassList, setLiveClassList] = useState([]);

    const [selectedBatchName, setSelectedBatchName] = useState(null);
    const [selectedCourseName, setSelectedCourseName] = useState(null);

    let navigate = useNavigate();

    const handleCenterChange = value => {
        setSelectedCenter(value || "");
    };

    const handleCourseChange = value => {
        setSelectedCourse(value || "");

        if(value) {
            courseList.map((course) => {  /* eslint-disable-line */
                if (course.id === value) {
                    setSelectedCourseName(course.class_name);
                }
            });
        } else {
            setSelectedCourseName("");
        }

        // getBatchList(e.target.value, selectedCenter);
    };

    const handleBatchChange = value => {
        setSelectedBatch(value || "");

        if(value) {
            batchList.map((batch) => { /* eslint-disable-line */
                if (batch.id === value) {
                    setSelectedBatchName(batch.section_name);
                }
            });
        } else {
            setSelectedBatchName("");
        }
    };

    const handleLiveClassChange = value => {
        if(!value) {
            setSelectedLiveClass("");
            return;
        }
        const liveClassId = value;
        // alert(liveClassId);
        if (liveClassId === "all") {
            let tempLiveClassIds = [];
            // eslint-disable-next-line array-callback-return
            liveClassList.map((liveClass) => {
                tempLiveClassIds.push(liveClass.liveclass_assoc.id)
            })
            setLiveClassIdList(tempLiveClassIds)
            setSelectedLiveClass('all');
            setLiveClassSubject("All subjects")

        } else {
            setLiveClassIdList([liveClassId])
            setSelectedLiveClass(liveClassId);
        }
        const liveClassData = liveClassList.find((live) => liveClassId === live.liveclass_assoc.id);

        if (liveClassData) {
            setLiveClassSubject(liveClassData.subject);
            setLiveClassStartDate(liveClassData.liveclass_assoc.start_date);
        }
    };

    useEffect(() => {
        setCourseList([]);
        setBatchList([]);
        setSelectedCourse("");
        setSelectedBatch("");
        if (selectedCenter) {
            getCourseList(selectedCenter);
        }
    }, [selectedCenter]);

    useEffect(() => {
        setBatchList([]);
        setSelectedBatch("");
        if (selectedCourse && selectedCenter) {
            getBatchList(selectedCourse, selectedCenter);
        }
    }, [selectedCenter, selectedCourse]);

    useEffect(() => {
        setSelectedLiveClass("");
        // if (selectedCourse && selectedCenter && selectedBatch && selectedDate) {
        //     getLiveClassList(
        //         selectedCourse,
        //         selectedCenter,
        //         selectedBatch,
        //         moment(selectedDate).format(`YYYY-MM-DD`)
        //     );
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCenter, selectedCourse, selectedBatch, selectedDate]);

    const getCourseList = async (schoolId) => {
        const data = await Api.doFetch(
            "GET",
            {},
            `${ApiUrl.GET_CLASS_SECTION_LIVE_CLASS}?school=${schoolId}`
        );

        if (data) {
            setCourseList(data.class_assoc);
        }
    };

    const getBatchList = async (classId, schoolId) => {

        const data = await Api.doFetch(
            "GET",
            {},
            `${ApiUrl.GET_CLASS_SECTION_LIVE_CLASS}?class=${classId}&school=${schoolId}`
        );
        if (data) {
            setBatchList(data.section_assoc);
            if (data.section_assoc.length === 1) {
                const [sectionOne] = data.section_assoc;
                setSelectedBatch(sectionOne.id)
                setSelectedBatchName(sectionOne.section_name);
            }
        }
    };

    const [liveclassLoading, setLiveClassLoading] = useState(false);

    const getLiveClassList = async (classId, schoolId, sectionId, date) => {

        setLiveClassLoading(true);

        const data = await Api.doFetch(
            "GET",
            {},
            `${ApiUrl.GET_CLASS_SECTION_LIVE_CLASS}?class=${classId}&school=${schoolId}&section=${sectionId}&date=${date}`
        );

        setLiveClassLoading(false);

        if (data) {
            setLiveClassList(
                data.live_class_assoc && data.live_class_assoc.length > 0
                    ? data.live_class_assoc
                    : []
            );
        }
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",
                        },
                    },
                }}
                className="add_course_modal_index"
            >
                <DialogTitle>
                    <div
                        style={{
                            display: "flex",
                            padding: "6px",
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="mark-att-modal-head">
                            Mark Attendance
                        </div>
                        <button
                            onClick={() => {
                                close();
                                setSelectedLiveClass("");
                                setSelectedCenter("");
                                setSelectedCourse("");
                                setSelectedBatch("");
                            }}
                            className="close-modal-btn"
                        >
                            x
                        </button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 10 }} className = "antd_dropdown">
                        <div style={{ marginBottom: "20px" }}>
                            <p className="add_course_drop_header">
                                Select Center <Asterisk />
                            </p>
                            <Select
                                className = "full-width"
                                value = {selectedCenter || undefined}
                                placeholder = "Select center"
                                onChange = {handleCenterChange}
                                showArrow
                                allowClear
                                showSearch
                                optionFilterProp="children"
                            >
                                {
                                    centerList.map((school) => {
                                        return (
                                            <Option
                                                key={school.school_id}
                                                value={school.school_id}
                                            >
                                                {school.school_name}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </div>

                        <div style={{ marginBottom: "20px" }}>
                            <p className="add_course_drop_header">
                                Select Course <Asterisk />
                            </p>
                            <Select
                                className = "full-width"
                                onChange = {handleCourseChange}
                                value = {selectedCourse || undefined}
                                placeholder = "Select course"
                                showArrow
                                allowClear
                                showSearch
                                optionFilterProp = "children"
                            >                                    
                                {
                                    courseList.map((course) => {
                                        return (
                                            <Option
                                                key={course.id}
                                                value={course.id}
                                            >
                                                {course.class_name}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </div>

                        <div style={{ marginBottom: "20px" }}>
                            <p className="add_course_drop_header">
                                Select Batch <Asterisk />
                            </p>
                            <Select
                                className = "full-width"
                                value = {selectedBatch || undefined}
                                placeholder = "Select batch"
                                onChange = {handleBatchChange}
                                showArrow
                                allowClear
                                showSearch
                                optionFilterProp = "children"
                            >
                                {
                                    batchList.map((batch) => {
                                        return (
                                            <Option
                                                key={batch.id}
                                                value={batch.id}
                                            >
                                                {batch.section_name}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </div>

                        <div>
                            <p className="add_course_drop_header">
                                {/* Select date <Asterisk /> */}
                            </p>

                            <p>{`Date: `+moment(selectedDate).format(` DD/MM/YYYY`)}</p>
                            {/* <DatePicker
                                allowClear={false}
                                // getPopupContainer={(triggerNode) => {
                                //     return triggerNode.parentNode;
                                // }}
                                className="attendance_date_picker"
                                format={"DD/MM/YYYY"}
                                value={selectedDate}
                                // onChange={date => {
                                //     setSelectedDate(date);
                                // }}
                            /> */}

                        </div>

                        {/* <div style={{ marginBottom: "20px" }}>
                            <p className="add_course_drop_header">
                                Select subject <Asterisk />
                            </p>
                            <Select
                                className = "full-width"
                                value = {selectedLiveClass || undefined}
                                placeholder = "Select subject"
                                onChange = {handleLiveClassChange}
                                showArrow
                                allowClear
                                showSearch
                                optionFilterProp = "children"
                            >
                                {
                                    liveClassList && liveClassList.length > 0 &&
                                    <Option value="all">
                                        All subjects
                                    </Option>
                                }
                                {
                                    liveClassList.map((liveClass) => {
                                        return (
                                            <Option
                                                key={liveClass.liveclass_assoc.id} 
                                                value={liveClass.liveclass_assoc.id}
                                            >
                                                {liveClass.subject} {formatClassTime(liveClass.liveclass_assoc.start_date)}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </div> */}

                        {/* {
                            !liveclassLoading &&
                            selectedBatch !== null &&
                            selectedBatch !== "" &&
                            liveClassList.length === 0 && 
                            <div style={{ display: "flex" }}>
                                <img
                                    alt={"Icon"}
                                    src={Iicon}
                                    style={{ margin: "8px" }}
                                />
                                <p className="error_text">
                                    No class was scheduled for '
                                    {selectedBatchName}' on{" "}
                                    {moment(selectedDate).format(
                                        `DD MMMM, YYYY`
                                    )}{" "}
                                    for '{selectedCourseName}'
                                </p>
                            </div>
                        } */}

                        {
                            selectedBatch !== null &&
                            selectedBatch !== "" ?
                            <button
                                className="btn modal-btn-submit"
                                style={{
                                    justifyContent: "center",
                                    marginTop: 10,
                                }}
                                onClick={() => {
                                    if(whichPageToOpen=="LIST"){
                                        navigate("/student/list-attendance", {
                                            state: {
                                                section_id: selectedBatch,
                                                liveclass_id: ["88299"],
                                                date: selectedDate.format(`YYYY-MM-DD hh:mm:ss`),
                                                liveClassStartDate,
                                                batchName: selectedBatchName,
                                                subjectName: liveClassSubject,
                                            },
                                        });
                                    }
                                    if(whichPageToOpen=="MARK"){
                                        navigate("/student/mark-attendance", {
                                            state: {
                                                section_id: selectedBatch,
                                                liveclass_id: ["88299"],
                                                date: selectedDate.format(`YYYY-MM-DD hh:mm:ss`),
                                                liveClassStartDate,
                                                batchName: selectedBatchName,
                                                subjectName: liveClassSubject,
                                            },
                                        });
                                    }
                                }}
                            >
                                Submit
                            </button>
                            :
                            <button
                                disabled={true}
                                className="btn modal-btn-submit-disabled"
                                style={{
                                    justifyContent: "center",
                                    marginTop: 10,
                                    fontWeight: "900",
                                }}
                            >
                                Submit
                            </button>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default SelectLivClassDialoge;
