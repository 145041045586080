import React from "react";
import {
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Avatar,
    TableCell,
    TableHead,
} from "@mui/material";
import PresentHand from "../assets/attendance Module/noun-raise-hand-4160048 1.svg";
import FingerPrint from "../assets/attendance Module/Fingerprint.svg";
import Online from "../assets/attendance Module/online.svg";
import { makeStyles } from "@mui/styles";
import { generateProfileInitials } from "../../../utils/helperFunctions";
import { Button } from "antd";

function ViewAttendanceTable({
    markedPresent,
    totalStudents,
    alreadyPresent,
    attendanceData,
    changeMarkedPresent,
    showModal,
    finalList,
}) {
    var tempList1 = [];

    const useStyles = makeStyles((theme) => ({
        table: {
            minWidth: 650,
            borderCollapse: "separate",
            // borderSpacing: "0px 4px",
            marginTop: '-24px',
            marginBottom: 0
        },
        thead: {
            width: 500,
            "& th:first-child": {
                borderRadius: "0.5em 0 0 0.5em",
            },
            "& th:last-child": {
                borderRadius: "0 0.5em 0.5em 0",
            },
        },
        trow: {
            "& td:first-child": {
                borderRadius: "1em 0 0 1em !important",
            },
            "& td:last-child": {
                borderRadius: "0 0.5em 0.5em 0 !important",
            },
        },
        theadMarginTop: {
            height: "0px",
        }

    }));
    const classes = useStyles();

    return (<>
        <div
            style={{
                backgroundColor: "white",
                paddingBottom: 15,
                paddingTop: 15,
                marginBottom: 15,
                borderRadius: "15px",
                height: 62,
                position: "sticky",
                top: "170px",
                zIndex: 999,
                overflowX: "auto",
                overflowY: 'hidden',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <table style={{ width: "100%", minWidth: 900 }}>
                <thead>
                    <tr>
                        <td width="45%" style={{ fontFamily: "Montserrat", fontWeight: 600, fontSize: "22px", textAlign:'center' }}>
                            Student Name
                        </td>
                        <td
                            width="20%"
                            style={{
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                                fontSize: "22px",
                                textAlign: 'center',
                            }}
                        >
                            Mobile Number
                        </td>
                        <td width="10%" style={{ padding: "0 13px" ,fontFamily: "Montserrat",
                                fontWeight: 600,
                                fontSize: "18px",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                padding: "0 13px",
                                textAlign: 'center',}}>
                           Total Present
                        </td>
                        <td width="10%" style={{ padding: "0 13px" ,fontFamily: "Montserrat",
                                fontWeight: 600,
                                fontSize: "18px",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                padding: "0 13px",
                                textAlign: 'center',}}>
                           Present Month
                        </td>
                        <td width="5%" style={{ padding: "0 13px" ,fontFamily: "Montserrat",
                                fontWeight: 600,
                                fontSize: "22px",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                padding: "0 13px",
                                textAlign: 'center',}}>
                            Action
                        </td>
                    </tr>
                </thead>
            </table>
        </div>
        <div style={{ height: 'calc(100% - 62px - 62px)', paddingBottom: 100, }}>
        <TableContainer sx={{ maxHeight: "100%", }}>
            <Table
                className={classes.table}
                stickyHeader
                aria-label="sticky table"
                sx={{
                    minWidth: 900,
                    borderSpacing: '0px 6px',
                }}
                style={{
                    fontFamily: "Montserrat",
                    backgroundColor: "rgb(229,229,229)",
                }}
            >
                <TableHead classes={{ root: classes.theadMarginTop }}>
                    <TableRow>
                        <TableCell component="th" sx={{ width: "45%", maxHeight: "0px", paddingTop: 0, paddingBottom: 0, background: 'transparent', border: 0, fontSize: 0, visibility: 'hidden', 'overflow': 'hidden' }}>&nbsp;</TableCell>
                        <TableCell component="th" sx={{ width: "20%", maxHeight: "0px", paddingTop: 0, paddingBottom: 0, background: 'transparent', border: 0, fontSize: 0, visibility: 'hidden', 'overflow': 'hidden' }}>&nbsp;</TableCell>
                        <TableCell component="th" sx={{ maxHeight: "10%", paddingTop: 0, paddingBottom: 0, background: 'transparent', border: 0, fontSize: 0, visibility: 'hidden', 'overflow': 'hidden' }}>&nbsp;</TableCell>
                        <TableCell component="th" sx={{ maxHeight: "10%", paddingTop: 0, paddingBottom: 0, background: 'transparent', border: 0, fontSize: 0, visibility: 'hidden', 'overflow': 'hidden' }}>&nbsp;</TableCell>
                        <TableCell component="th" sx={{ maxHeight: "5%", paddingTop: 0, paddingBottom: 0, background: 'transparent', border: 0, fontSize: 0, visibility: 'hidden', 'overflow': 'hidden' }}>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {finalList?.map((student) => (
                        <TableRow classes={{ root: classes.trow }}
                            style={{
                                // marginTop: "30px",
                               
                                backgroundColor: "white",
                            }}
                            key={student.student_assoc.id}
                        >
                            <TableCell style={{ width:"45%"}}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "40px",
                                      
                                        // backgroundColor: "red",
                                    }}
                                >
                                    {student.student_assoc.profile_pic ==
                                        null ? (
                                        <Avatar
                                            sx={{
                                                bgcolor:
                                                    "rgba(222, 229, 250, 1)",
                                                height: "80px",
                                                width: "80px",
                                                marginRight: "20px",
                                                fontsize: "2.9rem",
                                                color: "#012c63",
                                            }}
                                        >
                                            <p className="nameInitialAvatar">
                                                {generateProfileInitials(student.student_assoc.name)}
                                            </p>
                                        </Avatar>
                                    ) : (
                                        <Avatar
                                            sx={{
                                                height: "80px",
                                                width: "80px",
                                                marginRight: "20px",
                                            }}
                                        >
                                            <img alt={"Profile Pic"}
                                                src={
                                                    student.student_assoc
                                                        .profile_pic
                                                }
                                            />
                                        </Avatar>
                                    )}

                                    <div
                                        style={{
                                            marginLeft: 10,
                                            alignItems: "center",
                                        }}
                                    >
                                        <p className="student-name-tbl">
                                            {student.student_assoc.name}
                                        </p>
                                        <p className="student-id-tbl">
                                            Student ID:{" "}
                                            {student.student_assoc.id}
                                        </p>
                                    </div>
                                </div>
                            </TableCell>

                            <TableCell style={{ width:"20%",}} component="td" >
                                <p 
                                    style={{  textAlign:'center'}}
                                    className="mobile-number-tbl"
                                >
                                    ---- ----{" "}
                                    {student.student_assoc.mobile_number.substr(
                                        -4
                                    )}
                                </p>
                            </TableCell>

                            <TableCell component="td"
                                style={{
                                    width:"10%",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                }}
                            >
                                <div
                                    className="form-check present-div-cont"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    
                                >
                                    {student.attendance_count}
                                </div>
                            </TableCell>
                            <TableCell component="td"
                                style={{
                                    width:"10%",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                }}
                            >
                                <div
                                    className="form-check present-div-cont"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {student.attendance_count_month}
                                </div>
                            </TableCell>
                            <TableCell component="td"
                                style={{
                                    width:"5%",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                }}
                            >
                                <Button
                                     style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={()=>{
                                        showModal({student_id:student.student_assoc.id})
                                    }}
                                >
                                    View
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    </>

    );
}

export default ViewAttendanceTable;
