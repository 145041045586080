import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import ApiUrl from "../common/apiUrl";
import Api from "../common/api";
import Loader from "../common/Loader";
import { LineChart, Legend, XAxis, YAxis, CartesianGrid, Tooltip, Line } from "recharts";
import { Card, Divider, Space, Tag } from "antd";

export default function ProgressReport() {
	const navigate = useNavigate();
	const { roleList } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(true);
	const { centreID, batchID, student_id } = useParams();
	const legendRef = useRef(null);
	const chartRef = useRef(null);
	const [EXAM_LIST, setEXAM_LIST] = useState([]);
	const [data, setData] = useState([]);

	const getExamList = async () => {
		setLoading(true);
		const data = await Api.doFetch(
			"GET",
			{},
			`${ApiUrl.EXAM_LIST}${student_id}/?student_id=${student_id}&section_id=${batchID}&school_id=${centreID}&exam_type_id=1&skip=0&limit=20`
		);
		setLoading(false);
		if (data && data.status) {
			setEXAM_LIST(data.data);
			setLoading(false);
			data.data.map((item) => {
				if (item.exam_status == 4) {
					getRank(item);
				}
			});
		} else if (data.message && data.message === "Student not found") {
			// setNotFound(true);
		}
	};

	async function convertingChartData(exam_item, rank_item) {
		console.log(rank_item.student_data.rank);
		const obj = {
			exam_id: exam_item.id,
			exam_name: exam_item.exam_name,
			TotalPercentage: rank_item.student_data.percentage,
			RANK: rank_item.student_data.rank,
			TotalMark:rank_item.total_marks,
			MarkObtained:rank_item.student_data.marks_obtained
		};

		rank_item.subject_wise_result.forEach((ls) => {
			obj[`key_${ls.subject_detail.subject_assoc.subject_name}`] = ls.subject_detail.subject_assoc.subject_name.trim();
			var a = (ls.marks_obtained / ls.max_mark) * 100;
			obj[ls.subject_detail.subject_assoc.subject_name] = a.toFixed(2);
		});
		console.log(obj);
		// Using the previous state to ensure state update is handled correctly
		setData((prevData) => [...prevData, obj]);
	}

	const getRank = async (item) => {
		setLoading(true);
		const data = await Api.doFetch("GET", {}, `${ApiUrl.EXAM_RANK}${student_id}/?exam_ID=${item.id}`);
		setLoading(false);
		if (data && data.status) {
			convertingChartData(item, data.payload);
		} else if (data.message && data.message === "Student not found") {
			// setNotFound(true);
		}
	};

	useEffect(() => {
		getExamList();
	}, []);

	return (
		<div style={{ height: "100%", width: "100%", justifyContent: "center", justifyItems: "center" }}>
			{loading ? (
				<div className="loader_container">
					<Loader />
				</div>
			) : (
				<div style={{ backgroundColor: "white", height: "100%", width: "100%" }}>
					<div>
						<h3>Progress Report</h3>
					</div>
					<div style={{ display: "flex" }}>
						<LineChart width={1200} height={500} data={data}>
							<CartesianGrid strokeDasharray="3" />
							<XAxis dataKey="exam_name" strokeWidth={0.5} />
							<YAxis strokeWidth={0.1} padding={{ top: 20 }} />
							<Tooltip />
							<Legend />
							<Line type="monotone" dataKey="TotalPercentage" stroke="#ffaf49" strokeWidth={3} />
							{data.find((item) => item.key_Maths == "Maths") && <Line type="monotone" dataKey="Maths" stroke="#007f00" strokeWidth={3} />}
							{data.find((item) => item.key_Chemistry == "Chemistry") && (
								<Line type="monotone" dataKey="Chemistry" stroke="#6a9bb1" strokeWidth={3} />
							)}
							{data.find((item) => item.key_Physics == "Physics") && (
								<Line type="monotone" dataKey="Physics" stroke="#e2598b" strokeWidth={3} />
							)}
							{data.find((item) => item.key_Biology == "Biology") && (
								<Line type="monotone" dataKey="Biology" stroke="#3F51B5" strokeWidth={3} />
							)}
						</LineChart>
						<div style={{ marginLeft: 10, maxWidth: 300 }}>
							<Space direction="vertical">
								<Card title="Exam List">
									{EXAM_LIST.map((item, index) => {
										if ([4].includes(item.exam_status)) {
											const foundItem = data.find((dataItem) => dataItem.exam_id == item.id);
											if (foundItem) {
												return (
													<div>
														{index==0 ?<></> :<Divider /> }
														<h5>{item.exam_name}</h5>
														{/* <Tag bordered={false} color="green">
															Present
														</Tag> */}
														<Tag bordered={false} color="geekblue">
															{"RANK " + foundItem.RANK}
														</Tag>
														<Tag bordered={false} color="cyan">
															{"Percentage "+foundItem.TotalPercentage + "%"}
														</Tag>
														<Tag bordered={false} color="magenta" style={{marginTop:10}}>
															{"Marks Obtained "+foundItem.MarkObtained+" / "+foundItem.TotalMark}
														</Tag>
													</div>
												);
											}
										}
										if ([5, 6].includes(item.exam_status)) {
											return (
												<div>
													{index==0 ?<></>: <Divider />}
													<h5>{item.exam_name}</h5>
													<Tag bordered={false} color="error">
														Absent
													</Tag>
												</div>
											);
										}
									})}
								</Card>
							</Space>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
