import React, { useState } from "react";
import ListAttImage from "../../../assets/img/list-att-icon.svg";
import LiveClassModal from "./selectLivClassDialoge";
import { useNavigate } from "react-router-dom";

export default function Attendance() {
	const [openDialogue, setOpenDialogue] = useState(false);
	const [whichPageToOpen, setwhichPageToOpen] = useState("MARK");
	let navigate = useNavigate();

	return (
		<div className="containerMain">
			<div className="payment_reversal_container">
				<div
					className="payment_reversal_container_history"
					data-toggle="modal"
					data-target="#markAttModal"
					onClick={() => {
						setwhichPageToOpen("MARK");
						setOpenDialogue(true);
					}}
				>
					<img src={ListAttImage} alt="history" className="mb-3" />

					<span className="payment_reversal_container_text">Mark Attendance</span>
				</div>

				<div
					className="payment_reversal_container_history"
					onClick={() => {
						setwhichPageToOpen("LIST");
						setOpenDialogue(true);
					}}
				>
					<img src={ListAttImage} alt="history" className="mb-3" />

					<span className="payment_reversal_container_text">List of Attendance</span>
				</div>
				<div
					className="payment_reversal_container_history"
					onClick={() => {
						navigate("/student/mark-holiday", {
							state: {},
						});
					}}
				>
					<img src={ListAttImage} alt="history" className="mb-3" />
					<span className="payment_reversal_container_text">Mark Holiday</span>
				</div>
			</div>
			{openDialogue && (
				<div>
					<LiveClassModal
						isOpen={openDialogue}
						whichPageToOpen={whichPageToOpen}
						close={() => {
							setOpenDialogue(false);
						}}
					/>
				</div>
			)}
		</div>
	);
}
