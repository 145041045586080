import React from "react";
import { Table, TableBody, TableContainer, TableRow, Avatar, TableCell, TableHead } from "@mui/material";
import PresentHand from "../assets/attendance Module/noun-raise-hand-4160048 1.svg";
import FingerPrint from "../assets/attendance Module/Fingerprint.svg";
import Online from "../assets/attendance Module/online.svg";
import { makeStyles } from "@mui/styles";
import { generateProfileInitials } from "../../../utils/helperFunctions";
import { Button, message, Popconfirm } from "antd";

function AttendanceTable({ markedPresent, totalStudents, alreadyPresent, attendanceData, changeMarkedPresent, finalList ,markAbsent}) {
	var tempList1 = [];


	const useStyles = makeStyles((theme) => ({
		table: {
			minWidth: 650,
			borderCollapse: "separate",
			// borderSpacing: "0px 4px",
			marginTop: "-24px",
			marginBottom: 0,
		},
		thead: {
			width: 500,
			"& th:first-child": {
				borderRadius: "0.5em 0 0 0.5em",
			},
			"& th:last-child": {
				borderRadius: "0 0.5em 0.5em 0",
			},
		},
		trow: {
			"& td:first-child": {
				borderRadius: "1em 0 0 1em !important",
			},
			"& td:last-child": {
				borderRadius: "0 0.5em 0.5em 0 !important",
			},
		},
		theadMarginTop: {
			height: "0px",
		},
	}));
	const classes = useStyles();

	return (
		<>
			<div
				style={{
					backgroundColor: "white",
					paddingBottom: 15,
					paddingTop: 15,
					marginBottom: 15,
					borderRadius: "15px",
					height: 62,
					position: "sticky",
					top: "170px",
					zIndex: 999,
					overflowX: "auto",
					overflowY: "hidden",
					display: "flex",
					alignItems: "center",
				}}
			>
				<table style={{ width: "100%", minWidth: 900 }}>
					<thead>
						<tr>
							<td width="55%" style={{ fontFamily: "Montserrat", fontWeight: 600, fontSize: "22px", paddingLeft: "150px" }}>
								Student Name
							</td>
							<td
								width="20%"
								style={{
									fontFamily: "Montserrat",
									fontWeight: 600,
									fontSize: "22px",
									justifyContent: "center",
									alignItems: "flex-end",
									padding: "0 13px",
									textAlign: "center",
								}}
							>
								Mobile Number
							</td>
							<td style={{ padding: "0 13px" }}>
								<div className="form-check present-div-cont" style={{ display: "flex", fontWeight: 600, fontSize: 22 }}>
									<input
										type="checkbox"
										className="form-check-input att-check"
										style={{ marginRight: 15 }}
										checked={markedPresent.length > 0 && markedPresent.length === totalStudents - alreadyPresent}
										onChange={(e) => {
											// eslint-disable-next-line array-callback-return
											attendanceData.map((student, i) => {
												/* eslint-disable-line */
												if (student.status !== "Present" && e.target.checked === true) {
													if (markedPresent.indexOf(student.student_assoc.id) === -1) {
														tempList1.push(student.student_assoc.id);
													}
												}
											});

											changeMarkedPresent([...markedPresent, ...tempList1]);

											if (e.target.checked === false) {
												changeMarkedPresent([]);
												tempList1 = [];
											}
										}}
									/>
									Mark All Present
								</div>
							</td>
						</tr>
					</thead>
				</table>
			</div>
			<div style={{ height: "calc(100% - 62px - 62px)", paddingBottom: 100 }}>
				<TableContainer sx={{ maxHeight: "100%" }}>
					<Table
						className={classes.table}
						stickyHeader
						aria-label="sticky table"
						sx={{
							minWidth: 900,
							borderSpacing: "0px 6px",
						}}
						style={{
							fontFamily: "Montserrat",
							backgroundColor: "rgb(229,229,229)",
						}}
					>
						<TableHead classes={{ root: classes.theadMarginTop }}>
							<TableRow>
								<TableCell
									component="th"
									sx={{
										width: "55%",
										maxHeight: "0px",
										paddingTop: 0,
										paddingBottom: 0,
										background: "transparent",
										border: 0,
										fontSize: 0,
										visibility: "hidden",
										overflow: "hidden",
									}}
								>
									&nbsp;
								</TableCell>
								<TableCell
									component="th"
									sx={{
										width: "20%",
										maxHeight: "0px",
										paddingTop: 0,
										paddingBottom: 0,
										background: "transparent",
										border: 0,
										fontSize: 0,
										visibility: "hidden",
										overflow: "hidden",
									}}
								>
									&nbsp;
								</TableCell>
								<TableCell
									component="th"
									sx={{
										maxHeight: "0px",
										paddingTop: 0,
										paddingBottom: 0,
										background: "transparent",
										border: 0,
										fontSize: 0,
										visibility: "hidden",
										overflow: "hidden",
									}}
								>
									&nbsp;
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{finalList.map((student) => (
								<TableRow
									classes={{ root: classes.trow }}
									style={{
										// marginTop: "30px",
										backgroundColor: "white",
									}}
									key={student.student_assoc.id}
								>
									<TableCell component="td" scope="row">
										<div
											style={{
												display: "flex",
												alignItems: "center",
												marginLeft: "40px",
												// backgroundColor: "red",
											}}
										>
											{student.student_assoc.profile_pic == null ? (
												<Avatar
													sx={{
														bgcolor: "rgba(222, 229, 250, 1)",
														height: "80px",
														width: "80px",
														marginRight: "20px",
														fontsize: "2.9rem",
														color: "#012c63",
													}}
												>
													<p className="nameInitialAvatar">{generateProfileInitials(student.student_assoc.name)}</p>
												</Avatar>
											) : (
												<Avatar
													sx={{
														height: "80px",
														width: "80px",
														marginRight: "20px",
													}}
												>
													<img alt={"Profile Pic"} src={student.student_assoc.profile_pic} />
												</Avatar>
											)}

											<div
												style={{
													marginLeft: 10,
													alignItems: "center",
												}}
											>
												<p className="student-name-tbl">{student.student_assoc.name}</p>
												<p className="student-id-tbl">Student ID: {student.student_assoc.id}</p>
											</div>
										</div>
									</TableCell>

									<TableCell component="td">
										<p
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
											className="mobile-number-tbl"
										>
											---- ---- {student.student_assoc.mobile_number.substr(-4)}
										</p>
									</TableCell>

									<TableCell
										component="td"
										style={{
											fontWeight: 500,
											fontSize: "20px",
										}}
									>
										<div
											className="form-check present-div-cont"
											style={{
												display: "flex",
											}}
										>
											<input
												type="checkbox"
												className="form-check-input att-check"
												checked={
													markedPresent.indexOf(student.student_assoc.id) !== -1 || (student.mode === "mycrm" && student.attendance_assoc[0].status=='Present')
														? true
														: false
												}
												disabled={student.status === "Present" && true}
												onChange={(e) => {
													if (markedPresent.indexOf(student.student_assoc.id) === -1) {
														// console.log("Not Present");
														changeMarkedPresent([...markedPresent, student.student_assoc.id]);
														// console.log("Marked Students", markedPresent);
													} else {
														// console.log("Present");

														let temp = markedPresent;
														var myIndex = markedPresent.indexOf(student.student_assoc.id);

														temp.splice(myIndex, 1);
														changeMarkedPresent([...temp]);
														// console.log("Item Removed:", markedPresent);
													}
												}}
											/>
											{markedPresent.indexOf(student.student_assoc.id) !== -1 && (
												<div className="present-div-selected">
													<img alt={"Present"} src={PresentHand} className="present-hand-img" />
													Present
												</div>
											)}
											{student.mode === "mycrm" && student.attendance_assoc[0].status=='Present' ? (
												<div className="present-div-selected">
													<img alt={"PresentHand"} src={PresentHand} className="present-hand-img" />
													Present
												</div>
											) : student.status === "Present" && student.mode === "Offline" ? (
												<div className="offline-div-selected">
													<img alt={"FingerPrint"} src={FingerPrint} className="present-hand-img" />
													Biometric
												</div>
											) : student.status === "Present" && student.mode === "Online" ? (
												<div className="offline-div-selected">
													<img alt={"Online"} src={Online} className="present-hand-img" />
													Online
												</div>
											) : (
												""
											)}
										</div>
									</TableCell>
									<TableCell component="td">
										{student.mode === "mycrm" && student.attendance_assoc[0].status=='Present' &&(
												<Button
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														padding: 20,
														margin: 20,
													}}
													danger
													color="red"
													onClick={() => {
														markAbsent({id:student.attendance_assoc[0].id})
													}}
												>
													Mark Absent
												</Button>
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
}

export default AttendanceTable;
