import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../installmentCreate.css";
import addCircle from "../../../assets/img/addCircle.svg";
import Swal from "sweetalert2";
import Loader from "../../common/Loader";
import StudentCard from "../../student/detail/studentCard";
import Overview from "./overview";
import SummaryTable from "./summaryTable";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import PaymentHistory from "./paymentHistory";
import { currencyFormatter } from "../../../utils/helperFunctions";
import FeePayment from "./feePayment";
import HostelFeePayment from "./hostelFeePayment";
import NotFound from "../../common/notFound";
import { useMediaQuery } from "@mui/material";
import { segmentEvents } from "../../../utils/constants";
import { roleConstant } from "../../../utils/helperFunctions";
import { useSelector } from "react-redux";

const FeeSummary = () => {
    const isMobile = useMediaQuery("(max-width: 850px)");
    const isNavbarAdjust = useMediaQuery("(min-width: 600px) and (max-width: 904px)");

    const navigate = useNavigate()
    const { roleList } = useSelector(state => state.auth);

    const [courseHistoryLoading, setCourseHistoryLoading] = useState(false);
    const [hostelHistoryLoading, setHostelHistoryLoading] = useState(false);

    const { centreID, batchID, student_id } = useParams();
    // const [isLoading, setIsLoading] = useState(false);
    const [studentDetails, set_studentDetails] = useState(null);
    const [feeDetails, set_feeDetails] = useState(null);
    // const [selected_installment_plan, set_selected_installment_plan] = useState(null);
    const [installment_plans, set_installment_plans] = useState([]);
    const [payment_details, set_payment_details] = useState({
        paid_amount: 0,
        partial_paid: 0,
        unleased_amount: 0,
        unpaid_amount: 0,
    });
    const [hostel_payment_details, set_hostel_payment_details] = useState({
        paid_amount: 0,
        partial_paid: 0,
        unleased_amount: 0,
        unpaid_amount: 0,
        
    });

    const [hostelApplicable, sethostelApplicable] = useState(false)

    const [hostelCreated, setHostelCreated] = useState(false)

    const [isSociety, setIsSociety] = useState(false);

    // const [num, setNum] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState("cash");
    const [paymentAmount, set_paymentAmount] = useState("");
    const [paymentHistoryList, set_paymentHistoryList] = useState([]);
    // const [downloaodClicked, set_downloaodClicked] = useState(false);
    // const [next_installment, set_next_installment] = useState(null);
    // const [next_hostel_installment, set_next_hostel_installment] = useState(null);
    const [paymentMethodHostel, setPaymentMethodHostel] = useState("cash");
    const [hostelPaymentAmount, set_hostelPaymentAmount] = useState("");
    // const [paymentSummary, set_paymentSummary] = useState(null);
    const [hostelPaymentHistoryList, set_hostelPaymentHistoryList] = useState([]);
    // const [hostelPaymentSummary, set_hostelPaymentSummary] = useState(null);
    // const [downloadPaymentId, set_downloadPaymentId] = useState();

    const [hostel_installment_plans, set_hostel_installment_plans] = useState([]);
    const [hostel_info, set_hostel_info] = useState();
    const [currentTab, set_currentTab] = useState("Course");

    const [centre, setCentre] = useState("");
    const [course, setCourse] = useState("");
    const [batch, setBatch] = useState("");

    const [loading, setLoading] = useState(false);

    const [notFound, setNotFound] = useState(false);    

    const [hostelName, sethostelName] = useState("");

    const [hostelDesc, sethostelDesc] = useState("");

    const getStudentDetails = async () => {
        setLoading(true);

        const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_DETAILS}?student_id=${student_id}`);

        setLoading(false);
        if (data && !data.message) {
            set_studentDetails(data);
        } else if (data.message && data.message === "Student not found") {
            setNotFound(true);
        }
    }

    const getFeeDetails = async () => {

        const data = await Api.doFetch("GET", {}, `${ApiUrl.COURSE_DETAIL}?school_id=${centreID}&section_id=${batchID}`);

        if (data && data.status) {

            if (data.payload.length > 0) {
                set_feeDetails(data.payload[0]);

                setBatch(data.payload[0].section_assoc && data.payload[0].section_assoc.section_name);
                setCourse(data.payload[0].section_assoc && data.payload[0].section_assoc.class_name);
                setCentre(data.payload[0].school_name);
            }

        } else if (data && ((data.payload && data.payload.Error) || data.message)) {
            if (data.message === "school not found" || data.message === "section not found") {
                setNotFound(true);
            } else if (data.payload && data.payload.Error) {
                Swal.fire("Error", data.payload.Error, "error");
            } else if (data.message) {
                Swal.fire("Error", data.message, "error");
            }
        }

    }

    const [isCourseActive, setIsCourseActive] = useState(true);

    const [enableHDFC, setEnableHDFC] = useState(false);

    const getStudentInstallmentDetails = async () => {
        if (studentDetails?.student_data?.id) {

            const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_INSTALLMENT_DETAIL}${student_id}/?section_subject_id=${feeDetails.id}`);

            if (data && data.status) {
                set_installment_plans(data.payload.data);
                set_payment_details({
                    paid_amount: data.payload.paid_amount,
                    partial_paid: data.payload.partial_paid,
                    unleased_amount: data.payload.unleased_amount,
                    unpaid_amount: data.payload.unpaid_amount,
                    total_fee_amount: data.payload.total_fee_amount,
                });
                
                sethostelApplicable(data.payload.is_hostel_applicable);

                sethostelName(data.payload.hostel_name);

                setHostelCreated(data.payload.is_hostel_created);

                setIsCourseActive(data.payload.is_course_active);

                setIsSociety(data.payload.is_society_centre);

                setEnableHDFC(data?.payload?.hdfc_cc_avenue);

                // set_next_installment(data.payload.next_installment);
            } else if (data && ((data.payload && data.payload.Error) || data.message)) {
                if (data.payload && data.payload.Error) {
                    Swal.fire("Error", data.payload.Error, "error");
                } else if (data.message) {
                    Swal.fire("Error", data.message, "error");
                }
            }
        }
    };


    const getPaymentHistory = async () => {
        if (studentDetails?.student_data?.id) {

            const data = await Api.doFetch("GET", {}, `${ApiUrl.FEE_SUMMARY_PAYMENT_HISTORY}${student_id}/?section_subject_id=${feeDetails.id}`);

            if (data && data.status) {

                set_paymentHistoryList(data.payload.data);
                // set_paymentSummary(data.payload.payment_summary);

            } else if (data && ((data.payload && data.payload.Error) || data.message)) {
                if (data.payload && data.payload.Error) {
                    Swal.fire("Error", data.payload.Error, "error");
                } else if (data.message) {
                    Swal.fire("Error", data.message, "error");
                }
            }

            setCourseHistoryLoading(false);
        }
    };

    const getHostelInstallmentDetails = async () => {
        if (studentDetails?.student_data?.id && hostelCreated) {

            const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_HOSTEL_INSTALLMENT_DETAIL}${student_id}/`);

            if (data && data.status) {

                set_hostel_installment_plans(data.payload.data);
                set_hostel_info(data.payload.hostel_info);
                set_hostel_payment_details({
                    partial_paid: data.payload.partial_paid,
                });
                // set_next_hostel_installment(data.payload.next_installment);

                sethostelName(data.payload.hostel_info.hostel_name);
                sethostelDesc(data.payload.hostel_info.desc);

            } else if (data && ((data.payload && data.payload.Error) || data.message)) {
                if (data.payload && data.payload.Error) {
                    Swal.fire("Error", data.payload.Error, "error");
                } else if (data.message) {
                    Swal.fire("Error", data.message, "error");
                }
            }
        }
    };

    const getHostelPaymentHistory = async () => {
        if (studentDetails?.student_data?.id && hostel_info?.id) {

            const data = await Api.doFetch("GET", {}, `${ApiUrl.FEE_SUMMARY_HOSTEL_PAYMENT_HISTORY}${student_id}/?hostel_id=${hostel_info.id}`);

            if (data && data.status) {

                set_hostel_payment_details({
                    paid_amount: data.payload.payment_summary.paid_amount,
                    unleased_amount: data.payload.payment_summary.unreleased_amount,
                    total_fee_amount: data.payload.payment_summary.total_fee_amount,
                    unpaid_amount: data.payload.payment_summary.reaming_amount,
                });
                set_hostelPaymentHistoryList(data.payload.data);
                // set_hostelPaymentSummary(data.payload.payment_summary);

            } else if (data && ((data.payload && data.payload.Error) || data.message)) {
                if (data.payload && data.payload.Error) {
                    Swal.fire("Error", data.payload.Error, "error");
                } else if (data.message) {
                    Swal.fire("Error", data.message, "error");
                }
            }
            setHostelHistoryLoading(false);
        }
    };

    const handlePaymentMethod = (event) => {
        setPaymentMethod(event.target.value);
    };

    const updateInputValue = (event) => {
        set_paymentAmount(event.target.value);
    };

    const handlePaymentMethodHostel = (event) => {
        setPaymentMethodHostel(event.target.value);
    };
    const updateHostelInputValue = (event) => {
        set_hostelPaymentAmount(event.target.value);
    };

    const goToPayment = () => {
        if (paymentMethod === "razorpay") {
            Swal.fire("Coming Soon!");
            return false;
        }
        if (paymentAmount > 0) {
            if (paymentAmount > payment_details.unpaid_amount) {
                set_paymentAmount("");
                Swal.fire(
                    `You are entering ${currencyFormatter(
                        Number(paymentAmount)
                    )} which is greater than fee remaining ${currencyFormatter(
                        payment_details.unpaid_amount
                    )}.Please enter valid amount!`
                );
                return false;
            }
            // props.history.push({
            //   pathname: `/support/studentmodule/payment-details/${studentDetails.student_data.id}`,
            //   state: { paymentMethod: paymentMethod, paymentAmount: paymentAmount }
            // });
            navigate(`/payment/paymentDetails/${studentDetails.student_data.id}`, {
                state: {
                    paymentMethod: paymentMethod,
                    paymentAmount: paymentAmount,
                    school_id: centreID,
                    section_id: batchID,
                    school_name: centre,
                    section_name: batch,
                },
            });
        } else {
            Swal.fire("Amount is blank! Enter valid amount.");
            return false;
        }
    };

    const goToPaymentHostel = () => {
        if (paymentMethodHostel === "razorpay") {
            Swal.fire("Coming Soon!");
            return false;
        }
        if (hostelPaymentAmount > 0) {
            if (hostelPaymentAmount > hostel_payment_details.unpaid_amount) {
                set_hostelPaymentAmount("");
                Swal.fire(
                    `You are entering ${currencyFormatter(
                        Number(hostelPaymentAmount)
                    )} which is greater than fee remaining ${currencyFormatter(
                        hostel_payment_details.unpaid_amount
                    )}.Please enter valid amount!`
                );
                return false;
            }
            navigate(
                `/payment/payment-details-hostel/${studentDetails.student_data.id}`,
                {
                    state: {
                        paymentMethod: paymentMethodHostel,
                        paymentAmount: hostelPaymentAmount,
                        hostel_id: hostel_info.id,
                        school_id: centreID,
                        section_id: batchID,
                        school_name: centre,
                        section_name: batch,
                    },
                }
            );
        } else {
            Swal.fire("Amount is blank! Enter valid amount.");
            return false;
        }
    };

    const clickTab = (tabName) => {
        set_currentTab(tabName);
    };

    const canViewReceipt = (status, payment_type) => {
        return (
            status === "Paid" || 
            (
                (payment_type === 'cheque' || payment_type === 'Cheque') && 
                (status === "Paid" || status === "Pending")
            ) || 
            (
                payment_type === 'Bank deposit' && 
                (status === "Paid" || status === "Pending")
            ) || 
            (
                payment_type === 'No cost EMI' &&
                (status === "Paid" || status === "Pending")
            )
        ) ? true : false;
    }
    
    const checkCashConfirmed = (cash_confirmed) => {
        return cash_confirmed ? true : false;
    }

    const canDoReversal = (payment_type, cash_confirmed) => {

        if (cash_confirmed) {
            return false;
        }

        switch (payment_type) {
            case "cash":
            case "Cash":
            case "cheque":
            case "Cheque":
            case "Bank Transfer":
            case "swipe":
            case "Swipe":
            case "Bank deposit":
            case "No cost EMI":
            case "online/swipe":
                return true;
            case "CommerceConnect":
            case "commerceconnect":
            case "razorpay":
            case "Razorpay":
            case "paytm":
            case "Paytm":
            case "ezetap":
            case "link":
                return false;
            default:
                return false;
        }
    }

    useEffect(() => {
        getStudentDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (studentDetails) {
            getFeeDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentDetails]);

    useEffect(() => {
        getStudentInstallmentDetails();
        getPaymentHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feeDetails]);

    useEffect(() => {
        getHostelInstallmentDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hostelCreated])
    

    useEffect(() => {
        getHostelPaymentHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hostel_info]);

    const  checkSociety = () => {
        return (
            hostelName === "nano hostel" 
            ||
            hostelName === "Madhapur hostel" 
            ||
            hostelName === "Visakhapatnam hostel" 
            ||
            hostelName === "Nallakunta Nano hostel"
        ) ? true : false;
    }

    const [reversalID, setReversalID] = useState("");
    const [reversalDeclined, setReversalDeclined] = useState("");

    const [togglePaymentModal, setTogglePaymentModal] = useState(false);

    if (notFound) {
        return <NotFound />
    }

    return (
        <>
            {/* Popup  Start */}
            <div
                className="modal fade paymentPopup"
                id="myModal"
                role="dialog"
                data-keyboard="false"
                data-backdrop="static"
            >
                <div className="modal-dialog">
                    <FeePayment
                        goToPayment = {goToPayment}
                        paymentMethod = {paymentMethod}
                        handlePaymentMethod = {handlePaymentMethod}
                        paymentAmount = {paymentAmount}
                        updateInputValue = {updateInputValue}
                        toggle = {togglePaymentModal}
                        isSociety = {isSociety}
                        enableHDFC = {enableHDFC}
                    />
                </div>
            </div>
            {/* Popup  End */}

            {/* Popup  Hostel Start */}
            <div className="modal fade paymentPopup" id="myModalHostel" role="dialog">
                <div className="modal-dialog">
                    <HostelFeePayment
                        goToPaymentHostel = {goToPaymentHostel}
                        handlePaymentMethodHostel = {handlePaymentMethodHostel}
                        paymentMethodHostel = {paymentMethodHostel}
                        hostelPaymentAmount = {hostelPaymentAmount}
                        updateHostelInputValue = {updateHostelInputValue}
                        toggle = {togglePaymentModal}
                        isSociety = {isSociety}
                    />
                </div>
            </div>
            {/* Popup Hostel End */}

            {/* History modal start*/}
            <div className="modal fade paymentPopup" id="historyModal" role="dialog">
                <div className="modal-dialog modal-lg">
                    <PaymentHistory
                        hostel_installment_plans = {hostel_installment_plans}
                        paymentHistoryList = {paymentHistoryList}
                        hostelPaymentHistoryList = {hostelPaymentHistoryList}
                        getPaymentHistory = {getPaymentHistory}
                        getHostelPaymentHistory = {getHostelPaymentHistory}
                        courseHistoryLoading = {courseHistoryLoading}
                        hostelHistoryLoading = {hostelHistoryLoading}
                        setHostelHistoryLoading = {setHostelHistoryLoading}
                        setCourseHistoryLoading = {setCourseHistoryLoading}
                        reversalID = {reversalID}
                        setReversalID = {setReversalID}
                        reversalDeclined={reversalDeclined}
                        setReversalDeclined={setReversalDeclined}
                        canViewReceipt = {canViewReceipt}
                        canDoReversal = {canDoReversal}
                        checkCashConfirmed = {checkCashConfirmed}
                        isCourseActive = {isCourseActive}
                    />
                </div>
            </div>
            {/* History modal end*/}

            <div className={`containerMain${isNavbarAdjust ? " adjust-nav" : ""}`}>
                {
                    loading ?
                    <div className="loader_container">
                        <Loader />
                    </div>
                    :
                    <>
                        <StudentCard
                            style={{
                                marginTop: "0rem"
                            }}
                            studentData={studentDetails}
                            getStudentDetails={getStudentDetails}
                            goback={() => {
                                navigate(`/student/detail/${student_id}`)
                            }}
                            // optionsNeeded={false}
                            fromFeeSummary = {true}
                        />

                        <div>
                            <div className="hostelWrap">
                                {
                                    !checkSociety() &&
                                    isCourseActive &&
                                    hostel_installment_plans.length === 0 &&
                                    hostelApplicable && !hostelCreated && (
                                        <a href="#?"
                                            className="add-hostel-btn"
                                            //href={`../create-hostel-installment/${student_id}`}
                                            onClick={(e) => {
                                                if (e) e.preventDefault();
                                                Api.trackEvent(segmentEvents.STUDENT.CLICKED_ADD_HOSTEL);
                                                navigate(`/payment/create-hostel-installment/${centreID}/${batchID}/${student_id}`)
                                            }}
                                        >
                                            <img src={addCircle} alt="Add Icon" />
                                            Add Hostel
                                        </a>
                                    )
                                }
                            </div>
                            <ul className="nav nav-tabs">
                                <li
                                    className={currentTab === "Course" ? "active" : ""}
                                    onClick={() => clickTab("Course")}
                                >
                                    <a
                                        data-toggle="tab"
                                        href="#CourseFee"
                                        className={`fee_summary_tab ${currentTab === "Course" ? "fee_summary_tab_selected" : "" }`}
                                    >
                                        Course Fee
                                    </a>
                                </li>
                                {hostelCreated && (
                                    <li
                                        className={currentTab === "Hostel" ? "active" : ""}
                                        onClick={() => clickTab("Hostel")}
                                    >
                                        <a
                                            data-toggle="tab"
                                            href="#HostelFee"
                                            className={`fee_summary_tab ml-3  ${currentTab === "Hostel" ? "fee_summary_tab_selected" : "" }`}
                                        >
                                            Hostel Fee
                                        </a>
                                    </li>
                                )}
                                {/*<li><a data-toggle="tab" href="#Summary">Summary</a></li> */}
                            </ul>

                            <div className="tab-content">
                                <div
                                    id="CourseFee"
                                    className={
                                        currentTab === "Course"
                                            ? "tab-pane fade in active"
                                            : "tab-pane fade"
                                    }
                                    style = {{
                                        backgroundColor: "#FFFFFF",
                                        border: "none"
                                    }}
                                >
                                    <div className={`courseDetailWrap${isMobile ? " is-mobile" : ""}`}>
                                        <div className="courseDetailWrap-inner">
                                            <div className="student_course_detail_centre_flex">
                                                <span className="student_fee_summary_detail_header">
                                                    Centre:
                                                </span>
                                                <p className="student_fee_summary_detail_value">
                                                    {centre}
                                                </p>
                                            </div>
                                            <div className="student_course_detail_others_flex">
                                                <span className="student_fee_summary_detail_header_light">
                                                    Course:
                                                </span>
                                                <p className="student_fee_summary_detail_value">
                                                    {course}
                                                </p>
                                            </div>
                                            <div className="student_course_detail_others_flex">
                                                <span className="student_fee_summary_detail_header_light">
                                                    Batch:
                                                </span>
                                                <p className="student_fee_summary_detail_value">
                                                    {batch}
                                                </p>
                                            </div>
                                        </div>
                                        <Overview
                                            noOfInstallment = {installment_plans.length}
                                            totalPayable = {payment_details.total_fee_amount}
                                            paidAmount = {payment_details.paid_amount}
                                            unrealisedAmount = {payment_details.unleased_amount}
                                            remainingAmount = {payment_details.unpaid_amount}
                                        />

                                        <div className={`installmentList payment-summary${isMobile ? " is-mobile" : ""}`}>
                                            <SummaryTable
                                                summaryPlans = {installment_plans}
                                            />
                                            <div className={`sapceBtn installmentList-buttons${isMobile ? " is-mobile" : ""}`}>
                                               {roleList.findIndex((x) => [roleConstant.COUNSELOR,roleConstant.CENTER_HEAD,roleConstant.ACCOUNTANT,roleConstant.STUDENT_MANAGER,roleConstant.MASTER_LOGIN].includes(x.role_name)) >= 0&& <button
                                                    className={
                                                        payment_details.unpaid_amount > 0 && isCourseActive ? 
                                                        "payNowBtn"  
                                                        : 
                                                        "payNowBtn btn disabled"
                                                    }
                                                    data-toggle="modal"
                                                    data-target="#myModal"
                                                    disabled={
                                                        payment_details.unpaid_amount > 0 && isCourseActive ? 
                                                        false
                                                        : 
                                                        true
                                                    }
                                                    onClick = {() => {
                                                        if(payment_details.unpaid_amount > 0 && isCourseActive){
                                                            setTogglePaymentModal(!togglePaymentModal) 
                                                        }
                                                    }}
                                                >
                                                    Pay Now
                                                </button>}
                                                <button
                                                    className="payhistBtn"
                                                    data-toggle="modal"
                                                    data-target="#historyModal"
                                                    onClick={() => {
                                                        setReversalID("");
                                                        setReversalDeclined("");
                                                    }}
                                                >
                                                    Payment History
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="HostelFee"
                                    className={
                                        currentTab === "Hostel"
                                            ? "tab-pane fade in active"
                                            : "tab-pane fade"
                                    }
                                    style = {{
                                        backgroundColor: "#FFFFFF",
                                        border: "none"
                                    }}
                                >
                                    <div className={`courseDetailWrap${isMobile ? " is-mobile" : ""}`}>
                                        <div className="courseDetailWrap-inner-1">
                                            <div className="student_course_detail_centre_flex_name">
                                                <span className="student_fee_summary_detail_header">
                                                    Name:
                                                </span>
                                                <p className="student_fee_summary_detail_value">
                                                    {hostelName}
                                                </p>
                                            </div>
                                            <div className="student_course_detail_others_flex_desc">
                                                <span className="student_fee_summary_detail_header_light">
                                                    Description:
                                                </span>
                                                <p className="student_fee_summary_detail_value">
                                                    {hostelDesc}
                                                </p>
                                            </div>
                                            
                                        </div>
                                        <div className="courseDetailWrap-inner">
                                            <div className="student_course_detail_centre_flex">
                                                <span className="student_fee_summary_detail_header">
                                                    Centre:
                                                </span>
                                                <p className="student_fee_summary_detail_value">
                                                    {centre}
                                                </p>
                                            </div>
                                            <div className="student_course_detail_others_flex">
                                                <span className="student_fee_summary_detail_header_light">
                                                    Course:
                                                </span>
                                                <p className="student_fee_summary_detail_value">
                                                    {course}
                                                </p>
                                            </div>
                                            <div className="student_course_detail_others_flex">
                                                <span className="student_fee_summary_detail_header_light">
                                                    Batch:
                                                </span>
                                                <p className="student_fee_summary_detail_value">
                                                    {batch}
                                                </p>
                                            </div>
                                        </div>

                                        <Overview
                                            noOfInstallment = {hostel_installment_plans.length}
                                            totalPayable = {hostel_payment_details?.total_fee_amount}
                                            paidAmount = {hostel_payment_details?.paid_amount}
                                            unrealisedAmount = {hostel_payment_details?.unleased_amount}
                                            remainingAmount = {hostel_payment_details?.unpaid_amount}
                                        />

                                        <div className={`installmentList payment-summary${isMobile ? " is-mobile" : ""}`}>
                                            <SummaryTable
                                                summaryPlans = {hostel_installment_plans}
                                            />
                                            <div className={`sapceBtn installmentList-buttons${isMobile ? " is-mobile" : ""}`}>
                                                {
                                                    (
                                                        hostelName === "nano hostel" 
                                                        ||
                                                        hostelName === "Madhapur hostel" 
                                                        ||
                                                        hostelName === "Visakhapatnam hostel" 
                                                        ||
                                                        hostelName === "Nallakunta Nano hostel"
                                                    ) 
                                                    ?
                                                    <div style={{ height: "71px" }}></div>
                                                    :
                                                    <button
                                                        className={
                                                            hostel_payment_details.unpaid_amount > 0 && isCourseActive ? 
                                                            "payNowBtn"
                                                            : 
                                                            "payNowBtn btn disabled"
                                                        }
                                                        data-toggle="modal"
                                                        data-target="#myModalHostel"
                                                        disabled={
                                                            hostel_payment_details.unpaid_amount > 0 && isCourseActive ?
                                                            false
                                                            :
                                                            true
                                                        }
                                                        onClick = {() => {
                                                            if(hostel_payment_details.unpaid_amount > 0 && isCourseActive){
                                                                setTogglePaymentModal(!togglePaymentModal);
                                                            }
                                                        }}
                                                    >
                                                        Pay Now
                                                    </button>

                                                }
                                                <button
                                                    className="payhistBtn"
                                                    data-toggle="modal"
                                                    data-target="#historyModal"
                                                >
                                                    Payment History
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </div>

        </>
    );
};

export default FeeSummary;
