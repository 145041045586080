import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import MarkAttendanceModal from "./markAttendanceModal";
import AttendanceTable from "./attendanceTable";
import AttendanceCard from "./attendanceCard";

import TickWhite from "../../../assets/img/tick_white.svg";

const ToastMessage = ({ type }) => (
    <div
        className="reversal_toast"
        style={{
            "--toast-status": "#35CB00",
            width: "300px"
        }}
    >
        <img
            src={TickWhite}
            alt={"success"}
            className="mr-2"
            style={{ width: "1.2rem" }}
        />
        Attendance saved successfully.
    </div>
)

const formatClassTime = date => date ? moment(date).format("hh:mm A") : "";
export default function MarkAttendance() {

    const { state } = useLocation();
    let navigate = useNavigate();

    const { section_id, liveclass_id, date, liveClassStartDate, batchName, subjectName } =
        state !== null
            ? state
            : {
                section_id: null,
                liveclass_id: null,
                date: null,
                liveClassStartDate: null,
                batchName: null,
                subjectName: null,
            };
    let data = localStorage.getItem("userdetail");
    data = JSON.parse(data);
    const token = data.token;
    const [openSumbitDialogue, setOpenSumbitDialogue] = useState(false);
    const [markedPresent, setMarkedPresent] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);
    const [attendanceData, setAttendanceData] = useState([]);
    const [alreadyPresent, setAlreadyPresent] = useState(0);
    const [presentOnline, setPresentOnline] = useState(0);
    const [presentOffline, setPresentOffline] = useState(0);
    const [totalStudents, setTotalStudents] = useState(0);
    const [alreadyMarkedByCounscellor, setAlreadyMarkedByCounscellor] = useState(0)

    const [search, setSearch] = useState("");
    // const [filteredList, setFilteredList] = useState([]);
    const [finalList, setFinalList] = useState([]);

    const filterList = (txt) => {
        let filteredName = attendanceData.filter(
            (e) =>
                e.student_assoc.name
                    .toLowerCase()
                    .indexOf(txt.toLowerCase()) !== -1
        );
        let filteredNumber = attendanceData.filter(
            (e) =>
                e.student_assoc.mobile_number
                    .toLowerCase()
                    .indexOf(txt.toLowerCase()) !== -1
        );
        let filteredID = attendanceData.filter(
            (e) => e.student_assoc.id.toString().toLowerCase()
            .indexOf(txt.toLowerCase()) !== -1
        );

        setSearch(txt);
        filteredName = filteredName.concat(
            filteredNumber.filter((item) => filteredName.indexOf(item) < 0)
        );

        filteredName = filteredName.concat(
            filteredID.filter((item) => filteredName.indexOf(item) < 0)
        );

        // setFilteredList(filteredName);

        if (txt.length > 0) {
            setFinalList(filteredName);
        }

        if (txt.length === 0) {
            setFinalList(attendanceData);
        }
    };


    useEffect(() => {
        if (state === null) {
            navigate("/student/attendance", {});
        }
        getStudentBatchAttendance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getStudentBatchAttendance() {
        const data = await Api.doFetch(
            "GET",
            {},
            `${ ApiUrl.STUDENT_BATCH_ATTENDANCEV3
            }?section_id=${ section_id }&liveclass_id=${ "88299" }&date=${ moment(
                date
            ).format(`YYYY-MM-DD`) }`
        );

        if (data && data.status) {
            setAttendanceData(data.payload.data);
            setFinalList(data.payload.data);
            var alreadyPresentCount = 0;
            var presentoffline = 0;
            var presentonline = 0;
            var totalStudents = 0;
            var previouslyMarkedByCounscellor = 0;
            data.payload.data.map((student) => {   /* eslint-disable-line */

                totalStudents++;
                if (student.status === "Present") {
                    alreadyPresentCount++;
                }
                if (
                    student.status === "Present" &&
                    student.mode === "mycrm"
                ) {
                    previouslyMarkedByCounscellor++;
                }
                if (
                    student.status === "" &&
                    student.mode === "mycrm"
                ) {
                    previouslyMarkedByCounscellor++;
                }


                if (
                    student.status === "Present" &&
                    student.mode === "Offline"
                ) {
                    presentoffline++;
                }
                if (
                    student.status === "Present" &&
                    student.mode=== "Online"
                ) {
                    presentonline++;
                }
            });
            setAlreadyPresent(alreadyPresentCount);
            setPresentOffline(presentoffline);
            setPresentOnline(presentonline);
            setTotalStudents(totalStudents);
            setAlreadyMarkedByCounscellor(previouslyMarkedByCounscellor);
            // setIsLoading(false);
        }
    }

    async function postAttendance() {
        let postData =
        {
            data: [{
                student_ids: markedPresent,
                liveclass_id: liveclass_id,
                date: date
            }]
        }
        const data = await Api.doFetch(
            "POST",
            postData,
            ApiUrl.STUDENT_BATCH_ATTENDANCE3
        );

        if (data.status) {
                toast(<ToastMessage />);
        } else {
            Swal.fire("Error!", "Internal Server Error", "error");
        }
    }

    async function markAbsent({id}) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${token}`);
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
          "attendance_id": id
        });
        
        const requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch("https://ap.zinedu.com/test/v3/mark-student-absent/", requestOptions)
          .then((response) => response.text())
          .then((result) => getStudentBatchAttendance())
          .catch((error) => console.error(error));
    }

    return (
        <div style={{ width: "100%", height: "calc(100vh - 87px)" }}>
            <div
                style={{ width: "100%", paddingInline: "40px" }}
                className="containerMain body-col containerMain-attendance"
            >
                <div className="top-bar-con">
                    <div
                        style={{
                            justifyContent: "start",
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                            padding: "15px 15px 15px 0",
                        }}
                    >
                        <TextField
                            sx={{
                                width: {xs: '100%', sm: "290px", md: '450px', lg: '500px'},
                                borderRadius: "12px",
                                fontFamily: "Montserrat",
                                fontSize: 14,
                            }}
                            style={{ backgroundColor: "white" }}
                            id="outlined-with-icon"
                            placeholder="Search by mobile number/ name"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                style: { fontSize: 16, borderRadius: "12px" },
                            }}
                            value={search}
                            onChange={(e) => filterList(e.target.value)}
                            variant="outlined"
                        />
                    </div>

                    <div className="inside-flex">
                        <div className="right-inside-tags">{batchName}</div>
                        <span className="divider-span">|</span>

                        <div className="right-inside-tags">
                            {moment(date).format("DD/MM/YYYY")}
                        </div>
                        <span className="divider-span">|</span>

                        <div className="right-inside-tags">
                            {subjectName} {subjectName !== "All subjects" && formatClassTime(liveClassStartDate)}
                        </div>
                    </div>
                </div>

               
                    <AttendanceTable
                        markedPresent={markedPresent}
                        totalStudents={totalStudents}
                        alreadyPresent={alreadyPresent}
                        attendanceData={attendanceData}
                        changeMarkedPresent={setMarkedPresent}
                        finalList={finalList}
                        markAbsent={markAbsent}
                    />
                
                {openSumbitDialogue && (
                    <div>
                        <MarkAttendanceModal
                            isOpen={openSumbitDialogue}
                            close={() => {
                                setOpenSumbitDialogue(false);
                            }}
                            attendanceData={attendanceData}
                            alreadyPresent={alreadyPresent}
                            markedPresent={markedPresent}
                            presentOnline={presentOnline}
                            presentOffline={presentOffline}
                            postAttendance={postAttendance}
                            emptyMarkedPresent={() => setMarkedPresent([])}
                            alreadyMarkedByCounscellor={alreadyMarkedByCounscellor}
                        />
                    </div>
                )}
            </div>
            <div className="footer footer-attendance" style={{ width: "100%" }}>
                <div className="bottom-submit-con">
                    <button
                        onClick={() => {
                            setOpenSumbitDialogue(true);
                        }}
                        className="btn submit-att-btn"
                    >
                        Submit Attendance
                    </button>
                </div>
            </div>
        </div>
    );
}
